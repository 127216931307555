import { useState } from 'react'

const useFunctionCall = (auth, functions, funcName, options) => {
  const [data, setData] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [variables, setVariables] = useState(
    typeof options !== 'undefined' ? options : {}
  )
  const firebaseFunction = functions.httpsCallable(funcName, {
    timeout: 540000
  })

  const functionCall = (body) => {
    setData(undefined)
    setLoading(true)
    setError(undefined)
    const dataCall = (b) => {
      auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          const opts = {
            ...variables,
            body: b,
            idToken,
            host: window.location.hostname,
            origin: window.location.origin
          }
          firebaseFunction(opts)
            .then((res) => {
              setLoading(false)
              switch (res.data.status) {
                case 'Success':
                  setData(res.data.message)
                  break
                case 'Error':
                  setError(res.data.message)
                  break
                default:
                  setError(JSON.stringify(res))
                  break
              }
            })
            .catch((err) => {
              setError(err.message)
            })
        })
        .catch((err) => {
          setError(err.message)
        })
    }

    dataCall(body)
  }

  const setVars = (opts) => {
    setVariables((vars) => ({ ...vars, ...opts }))
  }
  return {
    data,
    loading,
    error,
    variables,
    functionCall,
    setVars
  }
}

export { useFunctionCall }
